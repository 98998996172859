<template>
<div>
<NavBar/>
<main class="relative container mx-auto bg-white px-4">
  <div class="relative -mx-4 top-0 pt-[17%] overflow-hidden">
    <img class="absolute inset-0 object-cover object-top w-full h-full filter blur" src="https://images.unsplash.com/photo-1514525253161-7a46d19cd819?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Y29uY2VydCUyMHBvc3RlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80" alt="" />
  </div>


  <article class="max-w-prose mx-auto py-8">
    <h1 class="text-2xl font-bold">PRIVACY POLICY FOR PREMIUMTUTORSMW</h1>
    <h2 class="mt-2 text-sm text-gray-500">Admin, 28 November 2021</h2>

    <div class="mt-6">
      <h2 class="text-2xl font-bold">1. INTRODUCTION</h2>
    <p class="mt-4">
      1.1 PremiumTutorsmw recognizes the importance of protecting your privacy in respect of your personal data, as defined by the Protection of Personal Information Act, 4 of 2013 (“POPIA”), processed by us when you use our website and our services. 
      
    </p>
    <p>1.2 Furthermore, we are committed to protecting your personal data entrusted to us.</p>
   <p>1.3 This notice applies to you if you visit our website or if you are a customer who has requested the services that we provide.</p>
   <p>1.4 The notice provides the details of the personal data we collect from you, what we do with the personal data, how you might access the personal data and who it might be shared with. Kindly read this notice carefully, to understand how we will treat your personal data.
   </p>
   <p>1.5 Kindly take note that we may update this privacy notice at any time by posting the latest version on our website https://premiumtutorsmw.com/</p>
<p>1.6 We will notify you of any changes by placing a notice on the website or by sending you an email detailing the changes that we have made to this notice. 
</p>
<p>1.7 You must accept all the terms of this notice when you request our services. If you do not agree with anything stated in this notice or to our processing activities, as set out in this notice, kindly do not use our website or request our services.
</p>
<p>1.8 You may not access our website or request our services if you are younger than 18 years old or do not have the legal capacity to conclude legally binding contracts. 
</p>
<p>1.9 By accepting this notice, you are deemed to have read, understood, accepted, and agreed to be bound by all of its terms.
</p>
<p>1.10 Lastly, you may only send us your personal data or the data of another data subject, where you have their permission to do so.
</p>

 <h2 class="text-2xl font-bold">2. NATURE OF THE BUSINESS OF THE COMPANY</h2>
<p>PremiumTutorsmw Tutors provides learning support in the form of one on one tuition, occuring in person or online, as well as university tutoring, group tutoring, concessions facilitation, online courses, language tutoring courses, study skills courses, adult language tuition and other learning needs. </p>

 <h2 class="text-2xl font-bold">3. PROCESSING YOUR PERSONAL DATA</h2>
 <h2>3.1 Types of personal data collected and processed by the Company</h2>
 <p>While using our service or communicating with our staff, we may ask you to provide us with certain personal data that can be used to contact or identify you. The personal data that we collect from you, may include, but is not limited to:</p>

<ul>
   <li>Email address</li>
<li>First name and surname</li>
<li>Phone number</li>
<li>Learner’s first name and surname</li>
<li>Physical address</li>
<li>Learner’s first name and surname</li>
<li>Learner’s school</li>


</ul>
<h3>3.3 Tracking Technologies and Cookies</h3>
<p>3.3.1 We use Cookies and similar tracking technologies to track the activity on our service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our service. The technologies we use may include:</p>
  <ul>
   <li><b>Cookies or Browser Cookies.</b> A cookie is a small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our service. Unless you have adjusted your browser setting so that it will refuse Cookies, our service may use Cookies.</li>
     <li><b>Flash Cookies. </b> Certain features of our service may use local stored objects (or Flash Cookies) to collect and store information about your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how you can delete Flash Cookies, please read “Where can I change the settings for disabling, or deleting local shared objects?” </li>
   <li><b>Web Beacons.</b>Certain sections of our service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
  </ul>
  <h3>Necessary / Essential Cookies</h3>
  <ul><li>
   Type: Session Cookies
  </li>
  <li>Administered by: Us</li>
  <li>Purpose: These Cookies are essential to provide you with services available through the Website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.</li>
  </ul>
  <h3>Cookies Policy / Notice Acceptance Cookies</h3>
  <ul>
   <li>Type: Persistent Cookies</li>
   <li>Administered by: Us</li>
   <li>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
  </ul>
  <h3>Functionality Cookies</h3>
  <ul>
   <li>Type: Persistent Cookies</li>
   <li>Administered by: Us</li>
   <li>Purpose: These Cookies allow us to remember choices you make when you use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website.</li>
  </ul>
  <h2 class="text-2xl font-bold">4. PURPOSE OF COLLECTION AND USE OF YOUR PERSONAL DATA</h2>
   <p>The Company collects and uses your personal data for the following purposes:  </p>
   <p><b>4.1 To provide and maintain our service</b>, including to monitor the usage of our service.</p>
   <p><b>4.2 To manage your account:</b>To manage your registration as a user of the service. The personal data you provide can give you access to different functionalities of the service that are available to you as a registered user.</p>
   <p><b>4.3 For the performance of a contract:</b>The development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with us through the service.</p>
   <p><b>4.4 To contact you:</b>To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
   <p><b>4.5 To provide you</b>with news and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</p>
   <p><b>4.6 To manage your requests:</b>To attend and manage your requests to us.</p>
   <p><b>4.7 For marketing purposes: </b>To pursue lawful related marketing activities. We will not send you promotional messages, unless you have chosen to opt-into them. We may, however, send you one message asking you to opt-into promotional messages without you having opted-into promotional messages.</p>
   <p><b>4.8 For other purposes:</b>We may use your personal data for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our service, products, services, marketing and your experience.</p>
   <h2 class="text-2xl font-bold">5. SECURITY OF YOUR PERSONAL DATA</h2>
   <p>8.1. We take the security of your personal data seriously and will endeavour to comply with the relevant and applicable data protection laws.  While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>
   <p>8.2. We will take all appropriate, reasonable, technical and organizational measures to safeguard your personal data to prevent loss of damage to or unauthorized destruction of your personal data or unlawful access to or processing of your personal data. Our technical and organizational personal data security measures include: </p>
   <ul>
      <li>The storage of your personal data under password-secured databases; </li>
      <li>The restriction of access to your personal data to specific staff/personnel who are designated and mandated to manage your personal data; </li>
      <li>The restriction of access to all our premises where your personal data is stored in physical or digital form; and </li>
      <li>The binding of any third parties who handle your personal data to confidentiality obligations with respect to the same.</li>
   
   </ul>
   <h2 class="text-2xl font-bold">6. CHILDREN’S PRIVACY</h2>   
   <p>Our service includes the processing of children’s data (anyone under the age of 18). We will ensure that at all times, we have the necessary and required consent from a competent person, whether it be the parents or lawful guardians, to process the personal data of the child involved.</p>
   <h2 class="text-2xl font-bold">7. ACCESS TO YOUR PERSONAL INFORMATION</h2>  
   <ul>
      <li>10.1. You have the right to request access to any of your personal data we may hold.</li>
      <li>10.3 Where you have previously given your consent to process your personal data, you also have the right to request that we transmit your personal data to a different service provider.</li>
      <li>10.4. Where it may have been necessary to get your consent to use your personal data, at any moment, you have the right to withdraw that consent. If you withdraw your consent, we will cease using your personal data without affecting the lawfulness of processing based on consent before your withdrawal.</li>
      <li>10.5. If you would like to make any of the requests set out above, kindly direct your request to our Deputy Information Officer and contact us at admin@premiumtutorsmw.com</li>
   </ul>
  <h2 class="text-2xl font-bold">11. LINKS TO OTHER WEBSITES</h2>   
  <p>11.1. Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the privacy notice of every site you visit.

11.2 We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
   </div>
  </article>
    
  <Footer/>
</main>
</div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  
  components: {
    NavBar,
    Footer,
  }}
</script>
<style>
  .pt-\[17\%\] {
    padding-top: 17%;
  }
  .mt-\[-10\%\] {
    margin-top: -10%;
  }
  .pt-\[56\.25\%\] {
    padding-top: 56.25%;
  }
</style>