<template>
  <div class="bg-primary min-h-screen flex flex-col">
    <NavBar />
    <div class="
        max-w-sm
        mx-auto
        flex-1 flex flex-col
        items-center
        justify-center
        px-2
      ">
      <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
        
        
        <div v-if="success" class="text-center text-sm text-grey-dark mb-2">
           we have sent you a link to reset your password!
        </div>
        
      <div v-else>
        <h1 class="mb-8 text-3xl text-center">Forgot Your Password?</h1>
        <div  class="text-center text-sm text-grey-dark mb-2">
          Just enter your email address below and we'll send you a link to reset your password!
        </div>
       <v-text-field v-model="email" type="email" label="Email" placeholder="Email" outlined dense></v-text-field>
               
              <v-btn
        @click="handleLogin()"
          type="submit"
          color="teal"
          dark
          :loading="buttonLoader"
          class="
          loading
            w-full
            text-center
            py-3
            rounded
            bg-yellow-fade
            text-black
            hover:bg-green-dark
            focus:outline-none
            my-1
          "
        >
          Reset Password
        </v-btn>
      </div>
       


      </div>

      <div class="text-grey-dark mt-6">
        No need to reset your password?
        <a class="no-underline text-white border-b border-yellow text-yellow-fade" href="/auth/login">
          Login </a>.
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import DataService from "@/authentication/data.service";
export default {
  components: {
    NavBar,
  },
  setup() { },
  data() {
    return {
      loading: false,
      email: "",
      content1: "",
      success:false,
      buttonLoader:false,


    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.user;
    }
  },

  methods: {
    handleLogin() {
      this.buttonLoader = true;
      if (this.email && this.buttonLoader) {
        DataService.passwordResetRequest({"email":this.email}).then(
          (response) => {
            const result = response.data;
            console.log(result)
            this.success = true
          this.buttonLoader = false;
          },
          (error) => {
            this.buttonLoader = false;
            this.content1 =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );


      }

    }
  }
};
</script>
<style scoped>
a{
  color: aliceblue !important;
}
</style>
